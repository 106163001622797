// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.portal.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AppEnvironment } from '../app/app-environment';
import packageJson from '../../package.json'

const clientId = '82754d82-fc07-4bfa-97d4-652c847e0b6f';
const policyId = 'B2C_1A_SIGNUP_SIGNIN';
const authApiBaseUrl = 'https://api-auth-bsi-prd-005.trn.bsiconnect.app/api';

export const environment: AppEnvironment = {
  production: true,
  adminApiBaseUrl: 'https://api-admin-bsi-prd-005.trn.bsiconnect.app/api',
  authApiBaseUrl: authApiBaseUrl,
  documentApiBaseUrl: 'https://api-document-bsi-prd-005.trn.bsiconnect.app/api',
  storageApiBaseUrl: 'https://api-storage-bsi-prd-005.trn.bsiconnect.app/api',
  clientId: clientId,
  authority: `https://bsiconnectauthtrn.b2clogin.com/bsiconnectauthtrn.onmicrosoft.com/${policyId}`,
  knownAuthorities: ['https://bsiconnectauthtrn.b2clogin.com'],
  redirectUrl: 'https://portal-prd-005.trn.bsiconnect.app',
  authApiProtectedResource: `${authApiBaseUrl}/auth/token-from-credentials`,
  authApiScope: 'https://bsiconnectauthtrn.onmicrosoft.com/auth-api/all',
  buildId: '183400',
  buildNumber: '20250318.1',
  pipeline: 'artemis-core-trn',
  branch: 'refs/heads/main',
  stage: 'trn',
  repository: 'artemis-core',
  commit: 'efb772fa6234544f66024880697b5e41a7775f41',
  environmentId: '144',
  environmentName: 'bsi-connect-platform-trn-prd',
  version: packageJson.version,
  workspaceUrl: 'https://prd-005.trn.bsiconnect.app/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

